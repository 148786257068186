var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('app-text-search-field',{on:{"input":function($event){return _vm.handleSearchInput($event)}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('CompanySelect',{attrs:{"clearable":"","item-value":function (val) { return val.id; }},on:{"input":function($event){return _vm.select()}},model:{value:(_vm.searchParams.company_id),callback:function ($$v) {_vm.$set(_vm.searchParams, "company_id", $$v)},expression:"searchParams.company_id"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-sort":"","dense":"","headers":_vm.headers,"items":_vm.transactions.data,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('CompanyChip',{attrs:{"company":item.company}})]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.date))+" ")]}},{key:"item.quantity_available",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_available))+" ")]}},{key:"item.quantity_reserved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_reserved))+" ")]}},{key:"item.quantity_expedition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_expedition))+" ")]}},{key:"item.quantity_physic",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_physic))+" ")]}},{key:"item.quantity_transfer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_transfer))+" ")]}},{key:"item.balance_available",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_available))+" ")]}},{key:"item.balance_reserved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_reserved))+" ")]}},{key:"item.balance_expedition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_expedition))+" ")]}},{key:"item.balance_physic",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_physic))+" ")]}},{key:"item.balance_transfer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.balance_transfer))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:((_vm.statusData[item.status].color) + "--text")},[_vm._v(" "+_vm._s(_vm.statusData[item.status].text)+" ")])]}}],null,true)}),_c('app-pagination',{attrs:{"data":_vm.transactions},on:{"click":function($event){return _vm.select($event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }