<template>
  <div>
    <v-row>
      <v-col cols="3">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>

      <v-col cols="3">
        <CompanySelect
          v-model="searchParams.company_id"
          @input="select()"
          clearable
          :item-value="(val) => val.id"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          disable-sort
          dense
          :headers="headers"
          :items="transactions.data"
          :items-per-page="-1"
        >
          <template v-slot:[`item.company`]="{ item }">
            <CompanyChip :company="item.company" />
          </template>
          <template v-slot:[`item.product`]="{ item }">
            {{ item.product.name }}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ $format.dateBr(item.date) }}
          </template>

          <template v-slot:[`item.quantity_available`]="{ item }">
            {{ $format.decimal(item.quantity_available) }}
          </template>
          <template v-slot:[`item.quantity_reserved`]="{ item }">
            {{ $format.decimal(item.quantity_reserved) }}
          </template>
          <template v-slot:[`item.quantity_expedition`]="{ item }">
            {{ $format.decimal(item.quantity_expedition) }}
          </template>
          <template v-slot:[`item.quantity_physic`]="{ item }">
            {{ $format.decimal(item.quantity_physic) }}
          </template>
          <template v-slot:[`item.quantity_transfer`]="{ item }">
            {{ $format.decimal(item.quantity_transfer) }}
          </template>
          <template v-slot:[`item.balance_available`]="{ item }">
            {{ $format.decimal(item.balance_available) }}
          </template>
          <template v-slot:[`item.balance_reserved`]="{ item }">
            {{ $format.decimal(item.balance_reserved) }}
          </template>
          <template v-slot:[`item.balance_expedition`]="{ item }">
            {{ $format.decimal(item.balance_expedition) }}
          </template>
          <template v-slot:[`item.balance_physic`]="{ item }">
            {{ $format.decimal(item.balance_physic) }}
          </template>
          <template v-slot:[`item.balance_transfer`]="{ item }">
            {{ $format.decimal(item.balance_transfer) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div :class="`${statusData[item.status].color}--text`">
              {{ statusData[item.status].text }}
            </div>
          </template>
        </v-data-table>

        <app-pagination @click="select($event)" :data="transactions" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
export default {
  components: {
    CompanyChip,
    CompanySelect,
  },
  data() {
    return {
      searchParams: {},
      transactions: {},
      headers: [
        { text: "id", value: "id" },
        { text: "company", value: "company" },
        { text: "product", value: "product" },

        { text: "origin", value: "origin" },
        { text: "date", value: "date" },
        // { text: "cancelled_at", value: "cancelled_at" },
        // { text: "cancelled_by", value: "cancelled_by" },
        { text: "quantity_available", value: "quantity_available" },
        { text: "quantity_reserved", value: "quantity_reserved" },
        { text: "quantity_expedition", value: "quantity_expedition" },
        { text: "quantity_physic", value: "quantity_physic" },
        { text: "quantity_transfer", value: "quantity_transfer" },
        { text: "balance_available", value: "balance_available" },
        { text: "balance_reserved", value: "balance_reserved" },
        { text: "balance_expedition", value: "balance_expedition" },
        { text: "balance_physic", value: "balance_physic" },
        { text: "balance_transfer", value: "balance_transfer" },
        { text: "status", value: "status" },
      ],
      statusData: {
        processed: {
          color: "green",
          text: "Processado",
        },

        canceled: {
          color: "error",
          text: "Cancelado",
        },
      },
    };
  },

  created() {
    this.select();
  },

  methods: {
    select(page = 1) {
      this.searchParams.page = page;

      this.$http
        .index("stock/transactions", this.searchParams)
        .then((response) => {
          this.transactions = response.transactions;
        });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },
  },
};
</script>

<style>
</style>
